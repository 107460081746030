import React from "react";
import MapSection from '../../components/RideAccepted/Map-Section';
import './index.css';

const Test = () => {
    return (
      <div className="map-container">
        <MapSection />
        <div className="overlay"></div>
      </div>
    )
}

export default Test;